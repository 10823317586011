// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: #CD6124;
$secondary: blue;
$bright-white: #fff;
$off-white: #ffc107; // unused 
$brand: #5B667F;
$brand-accent: #6A898D;
$dark: #454E62;
$light: tint-color($dark, 50%);
$gray: #616161;
$primary-bgrd-color: #FAFAFA;
$secondary-bgrd-color: white;

$border-radius: 3.2px;
$borderline: solid tint-color($dark,50%);
$borderline-secondary: solid tint-color($gray,70%);

$body-secondary-color: $primary;
$navbar-light-active-color: shade-color($primary,20%);
$dropdown-border-color: tint-color($primary,70%);

$spacer-asc: 1rem;
$spacer-asc-half: 0.5*$spacer-asc;
$spacer-asc-quarter: 0.25*$spacer-asc;

$primary-font-size: 1rem;
$secondary-font-size: 0.875rem;

$primary-font-weight: 400;
$emphasis-font-weight: 700;

// 3. Include Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/bootstrap";

// Page Elements
html, .container-fluid{
  margin: 0;
  padding: 0;
  min-height: calc(100%);
  width: 100%;
}

body{
  padding-bottom: 0rem;
  margin-bottom: 0;
  min-height: calc(100%);
  background-color: $primary-bgrd-color;
}

#root { 
  width: 100%; 
  margin: 0;
  padding: 0;
}

// Text Styles
h1{
  font-size: 2*$primary-font-size; 
  font-weight: $emphasis-font-weight; 
  margin-bottom: $spacer-asc;
  padding: 0;
}

h2{
  font-size: 1.25*$primary-font-size;
  font-weight: $emphasis-font-weight;
  line-height: 120%; 
  text-transform: uppercase;
}

h3{
  font-size: $primary-font-size; 
  font-weight: $emphasis-font-weight;
  padding: 0 $spacer-asc-half 1.5*$spacer-asc $spacer-asc-half;
  margin-top: 1.5*$spacer-asc; 
  border-bottom: $borderline;
  display: flex;
}

p{
  &.instruct{
    margin-bottom: $spacer-asc;
  }

  &.guidance,
  &.mainpreview{
    padding: $spacer-asc-half;
  }

  &.guidance{
    font-style: italic;
  }

  &.list-head{
    font-weight: $emphasis-font-weight;
  }

  &.list-txt{
    margin-top: .3*$spacer-asc;
  }
}

.helper{
  font-size: $secondary-font-size;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
}

.center{
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
}

// Buttons
.btn{
  &.btn-primary{
    color: $bright-white;
    width: 100%;
  }

  &.btn-secondary{
    color: $secondary; 
    background-color: transparent;
    border: none;

    &:hover, &:active{
      color: $dark;
      background-color: transparent;
      border: none;
    }
  }

  &.btn-tinyedit-head{
    color: $primary;
    font-size: $primary-font-size;
    padding: $spacer-asc-quarter $spacer-asc-half;
    text-transform: uppercase;
    font-weight: $primary-font-weight; 
    text-align: left;
  }
  
  &.btn-edit, 
  &.btn-select,
  &.btn-delete{
    color: $secondary;
    font-size: $primary-font-size;
    padding: 0 0 0 1.5*$spacer-asc-half;
  }

  &.btn-delete{
    border-left: $borderline;
    padding-left: 1.75*$spacer-asc-half;
    margin-left: $spacer-asc-quarter;
  }

  &.btn-edit{
    padding-right: 2*$spacer-asc-half;
  }
  
  &.btn-create{
    color: $primary;
    font-size: $secondary-font-size;
    padding: $spacer-asc-quarter $spacer-asc-half;
    width: 100%;
    text-align: left;
  }

  &.btn-tinyedit-save,
  &.btn-tinyedit-cancel{
    color: $secondary;
    font-size:$secondary-font-size;
    padding: 0 $spacer-asc-half 0 $spacer-asc-half;
  }

  &.btn-arrow-up, 
  &.btn-arrow-down,
  &.btn-arrow-left,
  &.btn-arrow-right{
    padding: 0 0 0 0;
    transform: scale(1.4) translateY(-.15rem);
  }
  
  &:disabled{
    color: $light;
    border:none;
  }
}

.btn-group{
  &.edit-delete{
    margin-left:auto;
  }

  &.arrange{
    padding: $spacer-asc-half $spacer-asc-half 0 $spacer-asc-half;
    margin-left: auto;
  }

  &.cancel-save{
    margin-left: auto;
  }
}

// Navigation
.dropdown { 
  background-color: $primary;
  border-radius: $border-radius;
  $padding-nav: 1.4*$spacer-asc;
  padding: 0 $padding-nav 0 $padding-nav;
  margin: 0 $spacer-asc 0 $spacer-asc;
}

.dropdown-menu[data-bs-popper]{
  min-width:101%;
  margin-top: $spacer-asc-quarter;
  padding-bottom: $spacer-asc-half;
}

.dropdown-item{
  color: $brand;
}

.nav-link {
  color: $brand;

  &.main{
    margin-left: $spacer-asc;
    margin-right: $spacer-asc;
  }

  &.side{
    line-height:150%;
    color: $gray;

    &.level2{
      font-weight: 300;
      font-style: italic;
    }
  }

  &:hover, &:active, &:focus {
    color: $primary;
    font-weight: $emphasis-font-weight;
  }

  &.dropdown-toggle {
    color: $bright-white;
    
    &:hover { 
       color: tint-color($primary,85%);
    }

    &::after {
      display: none;
    }
  }
}

.nav-item{
  .side{
    padding: $spacer-asc-half $spacer-asc; 
    margin-bottom: $spacer-asc;

    &.level2{
      padding-top: 0;
      padding-bottom: $spacer-asc-half;
    }
  }
}
.navbar-brand{
  padding-left: $spacer-asc;
  color: $brand;

  &:hover{
    color: $brand-accent;
  }
}

.nav{
  &.side{
    border-right: $borderline;
    height: 100%;
    padding-right: 2*$spacer-asc;
  }
}

// div groups
.tinyedit-grp{
  margin-top:$spacer-asc;
  display:flex;
  vertical-align:middle;
}

.component-mu{
  color: darkblue;
  margin-bottom: 3rem;
  font-size: 0.9rem;
}

.row{
  &.page-head{
    margin-bottom:3*$spacer-asc;
  }

  &.sidebar-preview{
    height: 50vh;
  }

  .list-row{
    border-bottom: $borderline-secondary;
    padding: .5*$spacer-asc 0 0 0;
  }
}

.list-box{
  margin: 1.5*$spacer-asc (2*$spacer-asc) 6*$spacer-asc (-1.5*$spacer-asc);
  border-radius: $border-radius;
  padding: 2.5*$spacer-asc;

  &.draft{
    background-color: tint-color($primary,85%);
  }

  &.live{
    background-color: tint-color($brand-accent, 85%);
  }
  
}

// tinymce
.tinybox{
  width: 100%;
  margin-top: $spacer-asc-half;
  margin-bottom: 2*$spacer-asc;
}